<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ $vuetify.lang.t("$vuetify.testGroupList", testName) }}</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>

    <data-table
      :headers="headers"
      :items="groups"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template v-slot:[`item.action`]="{ item: { groupId, testId } }">
        <div style="width: 100px">
          <v-btn
            :to="{
              name: 'Result.Group.TestResult',
              params: { group_id: groupId, test_id: testId }
            }"
            color="primary"
            class="mb-1"
            >{{ $vuetify.lang.t("$vuetify.result") }}</v-btn
          >
        </div>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Test from "@/services/test";

export default {
  name: "Result.Group.Index",
  data() {
    return {
      totalData: 0,
      groups: [],
      loading: true,
      options: {
        search: undefined
      },
      headers: [
        { text: "Group Name", value: "groupName" },
        { text: "Action", value: "action" }
      ],
      chartData: [],
      testName: "",
      searchForm: null
    };
  },
  components: {
    DataTable
  },
  watch: {
    options: {
      handler() {
        this.getGroups();
      },
      deep: true
    }
  },
  computed: {
    testId() {
      return this.$route.params.test_id;
    }
  },
  created() {
    this.setNavbarTitle("Group Result");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getGroups() {
      this.groups = [];
      this.loading = true;
      const test = await Test.find(this.testId);
      this.testName = test.data.data.name;
      const groups = await Test.registeredGroups(this.testId, {
        ...this.options,
        sortBy: "groupName"
      });
      this.groups = groups.data.data.groups;
      this.totalData = groups.data.data.total_data;
      this.loading = false;
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>

<style scoped>
.pointer:hover {
  cursor: pointer;
}
</style>
